<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <v-img :src="require('@/assets/images/logos/dente.png')" max-height="60px" max-width="60px" alt="logo"
              contain class="me-3 "></v-img>

            <h2 class="text-2xl font-weight-semibold">
              Ihealth-Plus
            </h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="mb-2 text-center">
            Realize o cadastro da sua empresa
          </p>
        </v-card-text>

        <v-card-text v-if="mensagemErroTrue">
          <v-alert dense outlined type="error">
            {{ this.mensagemErro }}
          </v-alert>
        </v-card-text>


        <!-- login form -->
        <v-card-text ref="form">
          <v-form>
            <v-text-field v-model="usuario" outlined label="Usuário" placeholder="JohnDoe" hide-details class="mb-3"
              :rules="[() => !!usuario || 'Campo obrigatório']" :error-messages="errorMessages" ref="usuario">
            </v-text-field>


            <v-text-field v-model="senha" outlined :type="senhaVisible ? 'text' : 'password'" label="Senha" ref="senha"
              placeholder="············" :append-icon="senhaVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details @click:append="senhaVisible = !senhaVisible" class="mb-3"
              :rules="[() => !!senha || 'Campo obrigatório']" :error-messages="errorMessages"></v-text-field>

            <v-text-field v-model="nome" outlined label="Nome completo" placeholder="Nome Completo" hide-details
              ref="nome" class="mb-3" :rules="[rules.required]"></v-text-field>

            <v-text-field v-model="empresa" outlined label="Empresa" placeholder="Emprea" hide-details class="mb-3"
              :rules="[() => !!empresa || 'Campo obrigatório']" :error-messages="errorMessages" ref="empresa">
            </v-text-field>

            <v-text-field v-model="cnpj" outlined label="CNPJ" placeholder="CNPJ" v-mask="'##.###.###/####-##'"
              ref="cnpj" hide-details class="mb-3" :rules="[() => !!cnpj || 'Campo obrigatório']"
              :error-messages="errorMessages"></v-text-field>

            <v-text-field v-model="email" outlined label="E-mail" placeholder="E-mail" ref="email"
              :rules="[() => !!email || 'Campo obrigatório']" :error-messages="errorMessages" hide-details class="mb-3">
            </v-text-field>


            <v-checkbox v-model="termos" hide-details class="mt-1">
              <template #label>
                <div class="d-flex align-center flex-wrap">
                  <span class="me-2">Aceito os</span><a href="javascript:void(0)">termos de uso do sistema</a>
                </div>
              </template>
            </v-checkbox>

            <v-btn block color="primary" class="mt-6" @click="registrar()">
              Registrar
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2">
            Já tem uma conta ?
          </span>
          <router-link :to="{ name: 'login' }">
            Login
          </router-link>
        </v-card-text>

        <v-snackbar v-model="snackbar" :color="color">
          {{ text }}

          <template v-slot:action="{ attrs }">
            <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
              Fechar
            </v-btn>
          </template>
        </v-snackbar>

      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img class="auth-mask-bg" height="190"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)">

    <!-- tree -->
    <v-img class="auth-tree" width="247" height="185" src="@/assets/images/misc/tree.png"></v-img>

    <!-- tree  -->
    <v-img class="auth-tree-3" width="377" height="289" src="@/assets/images/misc/tree-3.png"></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import Vue from "vue";
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective);

export default {

  setup() {
    const senhaVisible = ref(false)
    const confirmarSenhaVisible = ref(false)
    const termos = false

    return {
      senhaVisible,
      confirmarSenhaVisible,
      usuario: "",
      senha: "",
      confirmarSenha: "",
      nome: "",
      empresa: "",
      cnpj: "",
      email: "",
      termos,
      mask: "###.###.###-##",
      mensagemErroTrue: false,
      mensagemErro: '',
      formHasErrors: false,
      errorMessages: '',
      snackbar: false,
      text: '',
      color: 'success',

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length <= 20 || 'Max 20 characters',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
    }
  },

  methods: {
    registrar: function () {
      this.formHasErrors = false

      Object.keys(this.form).forEach(f => {
        if (!this.form[f]) {
          this.formHasErrors = true
        }

        this.$refs[f].validate(true)
      })

      if (!this.formHasErrors) {

        if (!this.termos) {
          this.text = "Favor aceitar os termos de uso"
          this.color = "error"
          this.snackbar = true
          return
        }

        if (!this.validarCnpj(this.cnpj)) {
          this.text = "CNPJ inválido"
          this.color = "error"
          this.snackbar = true
          return
        }

        
           this.$http.post("registrar", {
            usuario: this.usuario,
            senha: this.senha,
            nome: this.nome,
            empresa: this.empresa,
            cnpj: this.cnpj,
            email: this.email
          })
          .then((res) => {
            if (res.data.status != undefined && res.data.status == 200) {
              this.color = "success"
              this.text = res.data.msg
              this.snackbar = true

              setTimeout(() => {
                this.$router.push('login')
              }, 3000)

            } else if (res.data.status != undefined && res.data.status == 500) {
              this.color = "error"
              this.text = res.data.msg
              this.snackbar = true
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    validarCnpj: function (cnpj) {
      cnpj = cnpj.replace(/[^\d]+/g, '');

      if (cnpj == '') return false;

      if (cnpj.length != 14)
        return false;

      // Elimina CNPJs invalidos conhecidos
      if (cnpj == "00000000000000" ||
        cnpj == "11111111111111" ||
        cnpj == "22222222222222" ||
        cnpj == "33333333333333" ||
        cnpj == "44444444444444" ||
        cnpj == "55555555555555" ||
        cnpj == "66666666666666" ||
        cnpj == "77777777777777" ||
        cnpj == "88888888888888" ||
        cnpj == "99999999999999")
        return false;

      // Valida DVs
      var tamanho = cnpj.length - 2
      var numeros = cnpj.substring(0, tamanho);
      var digitos = cnpj.substring(tamanho);
      var soma = 0;
      var pos = tamanho - 7;
      for (var i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
          pos = 9;
      }
      var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado != digitos.charAt(0))
        return false;

      tamanho = tamanho + 1;
      numeros = cnpj.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (var i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
          pos = 9;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado != digitos.charAt(1))
        return false;

      return true;
    }
  },

  computed: {
    form() {
      return {
        usuario: this.usuario,
        senha: this.senha,
        nome: this.nome,
        empresa: this.empresa,
        cnpj: this.cnpj,
        email: this.email,

      }
    },
  },

  watch: {
    name() {
      this.errorMessages = ''
    },
  },

  beforeMount() {
    var logado = localStorage.getItem("login")
    if (logado != undefined && logado == "true") {
      this.$router.push('dashboard')
    }
  }
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
